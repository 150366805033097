import { CheckoutData } from "./types";
import { komApiClient } from "./apiClient";

export const fetchProducts = async (
  category?: string,
  subcategory?: string
) => {
  try {
    // Build the query string dynamically
    let query = `/items/kom-products?`;

    // Add category to the query string if it's defined
    if (category) {
      query += `product_category=${encodeURIComponent(category)}&`;
    }

    // Add subcategory to the query string if it's defined
    if (subcategory) {
      query += `product_subcategory=${encodeURIComponent(subcategory)}&`;
    }

    // Remove the trailing "&" or "?" if no params were added
    query = query.endsWith("&") ? query.slice(0, -1) : query;

    // Make the API request using the dynamically built query string
    const res = await komApiClient.get(query);

    return res;
  } catch (error) {
    console.log(error);
  }
};

export const updateUser = async (
  email: string,
  phone_number: string,
  address?: string
) => {
  try {
    const res = await komApiClient.put(`/users/${email}`, {
      phone_number,
      address,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const createOrder = async (order: CheckoutData) => {
  try {
    const res = await komApiClient.post(`/orders`, order);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const fetchOrders = async (user_id: string) => {
  try {
    const res = await komApiClient.get(`/orders/user/${user_id}`);
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const addToWishList = async (email: string, product_id: string) => {
  try {
    const res = await komApiClient.put(`/users/${email}/wishlist/add`, {
      product_id,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};

export const removeFromWishList = async (email: string, product_id: string) => {
  try {
    const res = await komApiClient.put(`/users/${email}/wishlist/remove`, {
      product_id,
    });
    return res;
  } catch (error) {
    console.log(error);
  }
};
