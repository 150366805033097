import React from "react";
import logo from "../../assets/images/kom_logo.webp";

const Loader = () => {
  return (
    <div className="bg-loader backdrop-blur-sm absolute flex justify-center items-center h-screen w-full top-0 bottom-0 left-0 right-0 p-5">
      <div className="animate-pulse" style={{ zIndex: 1 }}>
        <img
          src={logo}
          alt="Knots of Macrame"
          className="max-h-96 w-full rounded-xl"
        />
      </div>
    </div>
  );
};

export default Loader;
