import React, { useState } from "react";
import { FaTwitter, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import { isEmailValid, isPhoneNumberValid } from "../lib/funcs";
import { komApiClient } from "../lib/apiClient";
import { toast } from "react-toastify";
import { Pendant } from "../assets/icons/Icons";
import Footer from "../components/Footer";

const Altijarah = () => {
  const [loading, setLoading] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [register, setRegister] = useState({
    full_name: "",
    email: "",
    phone_number: "",
    user_type: "altijarah",
  });

  const registerUser = async () => {
    if (
      register.full_name &&
      register.phone_number &&
      isPhoneNumberValid(register.phone_number) &&
      register.email &&
      isEmailValid(register.email)
    ) {
      setLoading(true);
      try {
        const res = await komApiClient.post("/users", register);
        // console.log(res);
        if (res?.status === 201) {
          toast.success("Registered successfully!");
          setRegistered(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false); // set loading to false once the request is done
      }
    } else if (register.full_name === "") {
      toast.error("Full name is required.");
    } else if (
      register.phone_number === "" ||
      !isPhoneNumberValid(register.phone_number)
    ) {
      toast.error("Invalid Phone number.");
    } else if (register.email === "" || !isEmailValid(register.email)) {
      toast.error("Invalid Email.");
    }
  };

  const currentUrl = encodeURIComponent(window.location.href); // Current page URL to share
  const shareText = encodeURIComponent(
    "Join me at Al Tijarah Carnival 2024 at Princess Green, Palace Grounds! Happening on 2nd Nov 2024, from 11 AM to 11 PM. Don't miss out on a day full of fun and excitement!"
  );

  const handleShareClick = (platform: string) => {
    let shareUrl = "";

    switch (platform) {
      case "twitter":
        shareUrl = `https://twitter.com/intent/tweet?url=${currentUrl}&text=${shareText}`;
        break;
      case "linkedin":
        shareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${currentUrl}&title=${shareText}`;
        break;
      case "whatsapp":
        shareUrl = `https://api.whatsapp.com/send?text=${shareText}%20${currentUrl}`;
        break;
      default:
        return;
    }

    if (shareUrl) {
      window.open(shareUrl, "_blank");
    }
  };

  return (
    <>
      <div className="flex justify-center w-screen min-h-screen h-full bg-center bg-cover bg-no-repeat bg-gray-900 bg-altijarah">
        <div className="flex flex-col items-center container">
          <div className="flex flex-col items-center mt-40">
            <div className="flex flex-row items-center space-x-4 mb-3">
              <div className="rotate-180">
                <Pendant />
              </div>
              <h1 className="text-3xl md:text-6xl font-bold">𝓐𝓵 𝓣𝓲𝓳𝓪𝓻𝓪𝓱</h1>
              <Pendant />
            </div>
            <h2 className="text-2xl uppercase">Carnival 2024</h2>
          </div>

          <div className="mt-10 text-center text-red-600 text-xl md:text-2xl font-semibold rounded-lg">
            <p>2nd November, Saturday</p>
            <i>Princess Green, Gate No-09, Palace Grounds</i>
            <p>11AM - 11PM</p>
          </div>

          {registered && (
            <div className="mt-14 mb-10 text-center space-y-2">
              <p className="text-lg font-semibold">
                Your Al Tijarah 2024 Carnival Pass is Ready!
              </p>
              <img
                src="https://knots-of-macrame.s3.ap-south-1.amazonaws.com/AltijarahPass.png"
                alt="Al Tijarah Pass"
                className="max-h-96"
                onContextMenu={(e) => e.preventDefault()}
                onDragStart={(e) => e.preventDefault()}
                style={{ pointerEvents: "none" }}
              />
              <p className="mx-2 text-sm font-medium">
                You would have received it in your email. Please show it at the
                entrance for entry.{" "}
                <i>Check your spam folder if you can't find it.</i>
              </p>
            </div>
          )}

          <div className="px-3">
            {!registered && (
              <div className="flex flex-col space-y-5 py-5 px-3 md:px-10 bg-amber-200 border border-amber-300 mt-10 rounded-lg">
                <p className="text-lg font-medium text-center">
                  Get Your Exclusive Al Tijarah 2024 Carnival Pass!!
                </p>
                <input
                  value={register.full_name}
                  placeholder="Enter your full name"
                  className="rounded-lg border p-3 bg-amber"
                  onChange={(e) =>
                    setRegister((prev) => ({
                      ...prev,
                      full_name: e.target.value,
                    }))
                  }
                  required
                />

                <div className="flex">
                  <p className="flex justify-center items-center bg-white text-gray-500 rounded-xl w-12 mr-1">
                    +91
                  </p>
                  <input
                    value={register.phone_number}
                    placeholder="Phone Number"
                    className="rounded-lg border p-3 flex-grow"
                    onChange={(e) =>
                      setRegister((prev) => ({
                        ...prev,
                        phone_number: e.target.value,
                      }))
                    }
                    maxLength={10}
                    required
                  />
                </div>

                <input
                  value={register.email}
                  placeholder="Email"
                  className="rounded-lg border p-3"
                  onChange={(e) =>
                    setRegister((prev) => ({
                      ...prev,
                      email: e.target.value.toLocaleLowerCase(),
                    }))
                  }
                  required
                />

                <button
                  disabled={loading}
                  className="select-none px-3 py-2 bg-[#25D366] rounded-md focus:outline-none hover:bg-green-400 hover:to-red-400 w-full self-center uppercase font-medium"
                  onClick={registerUser}
                >
                  {loading ? (
                    <>
                      <svg
                        className="animate-spin h-5 w-5 mr-2 text-black inline-block"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
                        ></path>
                      </svg>
                      Processing...
                    </>
                  ) : (
                    "Claim My Pass"
                  )}
                </button>
              </div>
            )}

            <div className="self-center text-center p-5 bg-white mt-5 rounded-lg border border-amber-300 w-full font-medium">
              <p>Invite your friends to join the fun!</p>
              <p>Share now!</p>
              <div className="flex flex-row justify-center space-x-3 mt-2">
                <button onClick={() => handleShareClick("whatsapp")}>
                  <FaWhatsapp color="#25D366" size={30} />
                </button>
                <button onClick={() => handleShareClick("twitter")}>
                  <FaTwitter color="#1DA1F2" size={30} />
                </button>
                <button onClick={() => handleShareClick("linkedin")}>
                  <FaLinkedin color="#0077B5" size={30} />
                </button>
              </div>
            </div>

            <div className="flex flex-col items-center bg-gradient-to-b from-cyan-200 to-cyan-50 p-5 rounded-lg max-w-lg my-10">
              <p className="text-sm lg:text-base mb-5 text-center">
                Sign up for the KOM Affiliate Program now to start earning
                unlimited lifetime commissions with a flat 15% on every sale you
                generate, while your customers enjoy a 5% discount—boost your
                earnings and cash out as your balance grows!
              </p>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://affiliate.knotsofmacrame.com"
                className="select-none px-3 py-2 bg-gradient-to-b from-cyan-200 to-cyan-400 border border-cyan-300 rounded-md focus:outline-none hover:from-cyan-500 hover:to-cyan-400 self-center font-medium"
              >
                Join the KOM Affiliate Program Now!
              </a>
            </div>
          </div>
        </div>
      </div>
      <Footer noMargin />
    </>
  );
};

export default Altijarah;
